<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="إضافة تصنيف">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الكود</label>
                <b-form-input v-model="code" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="en_name"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم باللغة الانجليزية</label>
                <b-form-input v-model="en_name" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addItemCategory">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة التصنيف</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" التصنيفات">
      <!-- search input -->
     

      <!-- table -->
     <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ totalFamilies }}      عدد المواد</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refFamiliesListTable"
        class="position-relative"
        :items="fetchPrice"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
         <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-item-category',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>تعديل</span>
              </b-dropdown-item-button > -->
                <b-dropdown-item-button @click="deleteItemCategory(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>

          </b-dropdown>
        </template>

         
            </b-table>

          

         
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFamilies"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BPagination } from 'bootstrap-vue';

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
 
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import usePriceoffersList from "./list";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
   BCard,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
   BTable,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
     BPagination,
BCard,
 BTable,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },
  setup(){
      const {
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchPrice,
      

      // Extra Filters
    } = usePriceoffersList()
    return {
      search: null,
      fetchPrice,

      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },

  data() {
    return {
      name: "",
      code:'',
searchTerm: '',
      en_name: "",
    
      columns: [
      {
          label: " الاسم",
          field: "name",
        },
        {
          label: "الكود ",
          field: "code",
        },
        {
          label: "لاسم باللغة الانجليزية",
          field: "en_name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
 //   this.getItemCategory()

  },

  methods: {

  handlePageChange(page) {
    this.currentPage = page;
   // console.log(this.currentPage)
    getItemCategory()
    // Fetch data for the new page using the updated current page value
  },


    deleteItemCategory(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/item-category/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
          //  this.getItemCategory()
          this.refetchData()
            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
    },
    addItemCategory() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            
                data = {
        en_name: this.en_name,
        code: this.code,
        name:this.name,
      };
          
      this.$http.post("/api/v1/item-category", data).then((res) => {

       // this.getItemCategory()
       this.refetchData()
       this.name= "";
       this.en_name= "";
       this.code= "";
   
       
        this.$swal({
          title: "",
          text: "تمت أضافة التصنيف بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getItemCategory() {
      this.$http.get(`/api/v1/item-category?page_size=${this.perPage}`).then((res) => {
    
        this.rows = res.data.data;
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>